import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite"
import { Modal, Form, Input, Button, App } from 'antd';
import NiceModal, {useModal} from '@ebay/nice-modal-react';

//import { AuthContext } from '../../routes/authcontext';
import { useAuth } from '../../routes/authcontext';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import AuthService from '../../services/auth.services';

//import "../../App.css";

type Props = { };

const Password = NiceModal.create(( props: Props) => {
    const modal = useModal();
    let {t} = useTranslation();
    const {user, login, logout} = useAuth();
    const { userStore } = useStore();
    const navigate = useNavigate();
    const {message} = App.useApp();
    const [form] = Form.useForm();

    const whereGo = () => {
        alert(t("password_relogin_msg"));
        AuthService.logout()
        .then(res => {
            logout();
            window.location.href ="/";
        })
        .catch( () => {
            logout();
            window.location.href = "/";
        });
    }

    const onFinish = (values: any) => {
        if(values.new_password !== values.new_password_2) {
            message.error(t("password_new_old_confirm"));
            return;
        }
        AuthService.change_password(values)
        .then( response => {
            if(response.data.status === 200 && response.data.code === 0) {
                message.success(t("password_password_changed"));
                modal.hide();
                whereGo();
                return;
            }
            if(response.data.status === 203) {
                message.success(t("password_wrong_old_password"));
                return;
            }
            if(response.data.status === 204) {
                message.success(t("password_faild_change_password"));
                return;
            }
        })
        .catch( () => {
            message.error(t("password_faild_change_password"))
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("failed:", error);
        alert(t("password_validation_msg"));
    };

    const onClear = () => {
        //modal.hide();
        form.resetFields();
        modal.hide();
    };
    
    return(
        <Modal
        title={t("password_form_title")}
        onOk={()=> modal.hide()}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
        footer={null}
        >
            <div>
            <div style={{textAlign: "center", paddingTop: 30, paddingBottom:30, fontSize: 18}}>{t("password_title")}</div>
            <Form
            form={form}
            name="passwordForm"
            labelCol={{span: 6}}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
                <Form.Item label={t("password_old_password")}>
                    <Form.Item
                    name="old_password"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: t("password_old_password_err_msg"),
                        },
                    ]}
                    >
                        <Input.Password
                        placeholder={t("password_old_password_placeholder_msg")}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                label={t("password_new_password")}>
                    <Form.Item 
                    name="new_password"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: t("password_new_password_err_msg"),
                        }
                    ]}
                    >
                        <Input.Password 
                        placeholder={t("password_new_password_placeholder_msg")} />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                label={t("password_new_password_2")}>
                    <Form.Item 
                    name="new_password_2"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: t("password_new_password_2_err_msg"),
                        }
                    ]}
                    >
                        <Input.Password 
                        placeholder={t("password_new_password_2_placeholder_msg")} />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 6,
                    span: 24,
                }}
                >
                <Button
                    style={{
                        width: '100px'
                    }}
                    onClick={() => {
                        onClear();
                    }}>
                        {t("password_cancel")}
                    </Button>
                    <Button 
                    style={{
                        margin: '0 8px',
                        width: '100px'
                    }}
                    type="primary" htmlType="submit">
                        {t("password_save")}
                    </Button>
                </Form.Item>
            </Form>
            </div>
        </Modal>
    )
});

export default observer(Password);