import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, ColorPicker, } from 'antd';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import MyFavorites from './myfavorites';
import OrderManagement from './ordermanagment';
import CourseBought from './coursebought';
import StudentCalendar from './studentcalendar';
import TeacherCalendar from './teachercalendar';

type Props = {};

const Console = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();

    const items: TabsProps['items'] = [
        {
          label: t("console_tab_teacher_calendar"),
          key: 'teacher',
          children: <TeacherCalendar />
        },
        {
          label: t("console_tab_student_calendar"),
          key: 'student',
          children: <StudentCalendar />
        },
        {
            label: t("console_tab_courses_bought"),
            key: 'courses',
            children: <CourseBought />
        },
        {
            label: t("console_tab_order_managememt"),
            key: 'orders',
            children: <OrderManagement />
        }, /*
        {
            label: t("console_tab_my_favorites"),
            key: 'favorites',
            children: <MyFavorites />
        },*/
    ];

    const onChange = (key:string) => {
        // console.log('click ', key);
        menuStore.setConsoleCurrent(key);
    };

    useEffect(() => {
        menuStore.setLeftCurrent('');
        menuStore.setRightCurrent('/console')
        //menuStore.setConsoleCurrent('student');
    }, []);

    return (
        <div>
            <div>
            <Tabs onChange={onChange} defaultActiveKey={menuStore.consoleCurrent} items={items} />
            </div>
        </div>
    )
};

export default observer(Console);