import { makeAutoObservable } from "mobx";

class Menu {
    left_current: string;
    right_current: string;
    console_current: string;

    constructor () {
        this.left_current = '/';
        this.right_current = '';
        this.console_current = 'student';

        makeAutoObservable(this);
    };

    get leftCurrent () {
        return this.left_current;
    };

    setLeftCurrent (current: string) {
        this.left_current = current;
    };

    get rightCurrent () {
        return this.right_current;
    };

    setRightCurrent (current: string) {
        this.right_current = current;
    };

    get consoleCurrent () {
        return this.console_current;
    };

    setConsoleCurrent (current: string) {
        this.console_current = current;
    };
};

const menuStore = new Menu();

export default menuStore;