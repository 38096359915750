import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite"
import { Modal, Form, Input, Button, Image, App, Select } from 'antd';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import uuid from 'react-uuid';

//import { AuthContext } from '../../routes/authcontext';
import { useAuth } from '../../routes/authcontext';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import { IUser } from '../../types';

import AuthService from '../../services/auth.services';

//import "../../App.css";

type Props = {
    forwardPath: string;
};

type Iverify = {
    verify_code_id: string;
    verify_code_image: string;
}

/*
const Login = (props: Props) => {
    const {user, login, logout} = useAuth();
    const { userStore } = useStore();
    //const {authenticated, setAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async () => {
        let me = {
            token: "pppp",
            userId: "1000",
        }
        userStore.setUserName("蔡建新");
        login(me);
        navigate('/');
    }

    return (
        <div>
            <Button 
            type="primary"
            onClick={() => handleLogin()}>
                Authenticate
            </Button>
        </div>
    )
};
*/

const Login = NiceModal.create(( {forwardPath}: Props) => {
    const modal = useModal();
    let {t} = useTranslation();
    const {user, login, logout} = useAuth();
    const { userStore } = useStore();
    const navigate = useNavigate();
    const {message} = App.useApp();
    const [form] = Form.useForm();

    const [verifyImage, setVerifyImage] = useState<Iverify>({verify_code_id: uuid(), verify_code_image: ""});
    const [refresh, setRefresh] = useState<number>(0);

    useEffect(() => {
        let isUnmount = false;
        AuthService.get_verify_code(verifyImage.verify_code_id)
        .then(response => {
            if(response.data.status === 200 && !isUnmount) {
                setVerifyImage({
                    ...verifyImage,
                    "verify_code_image": "data:image/png;base64," + response.data.verify_code,
                });
            } else {
                return;
            }
            
        })
        .catch();
        return () => {
            isUnmount = true;
        }
    }, [refresh]);

    const onVerifyCodeClick = () => {
        setRefresh(refresh+1);
    };
    
    const onFinish = (values: any) => {
        // console.log(values)
        values['verify_code_id'] = verifyImage.verify_code_id;
        AuthService.login(values)
        .then(response => {
            // console.log(response);
            AuthService.me()
            .then(res => {
                if (res) {
                    let _access_token = localStorage.getItem('access_token');
                    if (_access_token) {
                        try {
                            let me :IUser = {
                                token: _access_token,
                                userId: res.user_id,
                                userName: res.user_name,
                                account: res.account,
                                email: res.email,
                                role: res.role,
                                zone: res.zone,
                                countryCode: res.country_code,
                            };
                            login(me);
                            userStore.setAccessToken(_access_token);
                            userStore.setAccount(me.account);
                            userStore.setEmail(me.email);
                            userStore.setRole(me.role);
                            userStore.setUserId(BigInt(me.userId));
                            userStore.setUserName(me.userName);
                            userStore.setZone(me.zone);
                            userStore.setCountryCode(me.countryCode);
                            navigate(forwardPath);
                            modal.hide();
                        } catch {
                            message.error(t('login_msg_error'));
                        }
                    }
                } else {
                    message.error(t('login_msg_error'));
                }
            }, err => {
                console.log(err);
            })
        }, err => {
            // console.log(err);
            let res = err.response
            if(res.status === 401) {
                let reason = res.data.reasons[0];
                if (reason === 'Wrong verification code') {
                    message.error(t('login_msg_error_verifycode'));
                    return;
                }
                if (reason === 'User not found') {
                    message.error(t('login_msg_error_user_notfound'));
                    return;
                }
                if (reason === 'Wrong password') {
                    message.error(t('login_msg_error_wrong_passwd'));
                    return;
                }
            } 
            message.error(t('login_msg_error'));
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("failed:", error);
        alert(t("login_validation_msg"));
    };

    const onClear = () => {
        modal.hide();
    };

    const countryCodes = [
        { value: "86", label: "+86"},
        { value: "254", label: "+254"}
    ];

    const prefixSelector = (
        <Form.Item name="country_code" noStyle>
          <Select
            style={{
              width: 90,
            }}
            options={countryCodes}
          >
          </Select>
        </Form.Item>
    );
    
    return(
        <Modal
        title={t("login_form_title")}
        onOk={()=> modal.hide()}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
        footer={null}
        >
            <div>
            <div style={{textAlign: "center", fontSize: 24}}><img src="/logo.png" height="30" style={{transform: `translateY(20%)`}} /> {t("login_system_name")}</div>
            <div style={{textAlign: "center", paddingTop: 30, paddingBottom:30, fontSize: 18}}> {t("login_title")}</div>
            <Form
            form={form}
            name="loginForm"
            labelCol={{span: 6}}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{
                country_code: "254"
            }}
            >
                <Form.Item label={t("login_account")}>
                    <Form.Item
                    name="account"
                    noStyle
                    rules={[
                        {
                            required: true,
                            max: 18,
                            message: t("login_account_err_msg"),
                        },
                        {
                            //pattern:/^1[0-9]\d{9}$/,
                            pattern:/^\d{6,18}$/,
                            message: t("login_account_err_format_msg")
                        }
                    ]}
                    >
                        <Input
                        addonBefore={prefixSelector}
                        placeholder={t("login_account_placeholder_msg")}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                label={t("login_passwd")}>
                    <Form.Item 
                    name="password"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: t("login_passwd_err_msg"),
                        }
                    ]}
                    >
                        <Input.Password 
                        placeholder={t("login_passwd_placeholder_msg")} />
                    </Form.Item>
                </Form.Item>
                <Form.Item label={t("login_verify")}>
                    <Form.Item
                    name="verify_code"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: t("login_verify_err_msg")
                        },
                    ]}
                    >
                        <Input
                        style={{width: 120,}}
                        placeholder={t("login_verify_placeholder_msg")}
                        />
                    </Form.Item>
                    <Image 
                    preview={false}
                    src={verifyImage.verify_code_image} 
                    style={{width:120, cursor: "pointer"}} 
                    onClick={onVerifyCodeClick}
                    />
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 6,
                    span: 24,
                }}
                >
                <Button
                    style={{
                        width: '100px'
                    }}
                    onClick={() => {
                        onClear();
                    }}>
                        {t("login_cancel")}
                    </Button>
                    <Button 
                    style={{
                        margin: '0 8px',
                        width: '100px'
                    }}
                    type="primary" htmlType="submit">
                        {t("login_login")}
                    </Button>
                </Form.Item>
            </Form>
            </div>
        </Modal>
    )
});

export default observer(Login);