import React, { useEffect, useState} from 'react';
import { App, Row, Col, Form, Input, Select, Table, Button, Dropdown } from 'antd';
import type { PaginationProps } from 'antd';

import type { TableProps, TablePaginationConfig, MenuProps} from 'antd';
import { TableCurrentDataSource, SorterResult, FilterValue } from 'antd/es/table/interface';

import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import dayjs from 'dayjs';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import OrderService from '../../services/orders.services';

type Props = {};

type OrderDataType = {
    key: string;
    order_no: string;
    order_status: number;
    course_id: string;
    course_name: string;
    price: number;
    course_start_date_time: string;
}

const OrderManagement = (props: Props) => {

    const { userStore, menuStore, themeStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();
    const navigate = useNavigate();

    useEffect(() => {
        menuStore.setRightCurrent("/console");
    }, []);

    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({
        page_number: 1, 
        page_size: 20,
        count: 0,
        data: [],
    });
    const [refresh, setRefresh] = useState(0);

    const onFinish = (values:any) => {
        setCurrentPage(1);
        setRefresh(refresh+1);
    };

    const onClear = () => {
        form.resetFields();
        setCurrentPage(1);
        setRefresh(refresh+1);
    };

    useEffect(() => {
        let _search = {
            name: form.getFieldValue("name"),
            bought_time: form.getFieldValue("bought_time"),
            page_number: currentPage,
            page_size: 20,
        };

        OrderService.searchOrders(_search)
        .then(response => {
            if(response.data.status === 200 && response.data.code === 0) {
                setData({
                    ...data,
                    data: response.data.data.data,
                    count: response.data.data.count,
                    page_number: response.data.data.page_number,
                    page_size: response.data.data.page_size,
                });
                return
            } else {
                setData({
                    ...data,
                    data:[],
                    count: 0,
                    page_number: 1,
                    page_size: 20,
                })
            }
        })
        .catch(error => {
            message.error(t('orders_error_fetch_order'));
            setData({
                ...data,
                data:[],
                count: 0,
                page_number: 1,
                page_size: 20,
            })
        })
    }, [refresh, currentPage]);

    const onChange = (pagination: TablePaginationConfig, filters:Record<string, FilterValue | null>, 
        sorter: SorterResult<OrderDataType> | SorterResult<OrderDataType>[], 
        extra: TableCurrentDataSource<OrderDataType>) => {
        if(extra.action === "paginate") {
            if(pagination.current){
                setCurrentPage(pagination.current);
            }     
        }
    };

    const menuItems:MenuProps['items'] = [
        {label: t("button_payit"), key: "menu_payit"},
        {label: t("button_cancel"), key: "menu_cancel"},
        {label: t("button_return"), key: "menu_return"},
    ];
    
    const handleRowAction = (item:OrderDataType, key:string) => {
        if(key === "menu_payit") {
            if(item.order_status === 1) {
                navigate("/console/checkout/" + item.key);
                /*
                let _order = {
                    id: item.key,
                    payment_method: 1
                }
                OrderService.updateOrderPayment(_order)
                .then(response => {
                    if(response.data.status === 200 && response.data.code === 0) {
                        message.success(t("orders_payment_successfully"));
                        setRefresh(refresh+1);
                        return
                    } else {
                        message.error(t("orders_payment_failed"));
                        return
                    }
                })
                .catch(error => {
                    message.error(t("orders_payment_failed"));
                    return
                })
                */
            }
        }
        if(key === "menu_cancel") {
            let _order = {
                id: item.key,
                status: 3
            }
            OrderService.updateOrderStatus(_order)
            .then(response => {
                if(response.data.status === 200 && response.data.code === 0) {
                    message.success(t("orders_status_successfully"));
                    setRefresh(refresh+1);
                    return
                } else {
                    message.error(t("orders_status_failed"));
                    return
                }
            })
            .catch(error => {
                message.error(t("orders_status_failed"));
                return
            })
        }
        if(key === "menu_return") {
            alert(t("msg_ask_for_return"));
            /*
            let _order = {
                id: item.key,
                status: 4
            }
            OrderService.updateOrderStatus(_order)
            .then(response => {
                if(response.data.status === 200 && response.data.code === 0) {
                    message.success(t("orders_status_successfully"));
                    setRefresh(refresh+1);
                    return
                } else {
                    message.error(t("orders_status_failed"));
                    return
                }
            })
            .catch(error => {
                message.error(t("orders_status_failed"));
                return
            })
            */
        }
    };

    const columns:TableProps<OrderDataType>['columns'] = [
        {
            title: t("orders_record_order_no"), dataIndex: "order_no", key: "order_no", width:"20%",
        },
        {
            title: t("orders_record_course_name"), dataIndex: "course_name", key: "course_name", width:"16%",
            render: (_, record) => (
                <>
                <a onClick={
                    () => onDetailItem(record)
                }>{record.course_name}</a>
                </>
            ),
        },
        {
            title: t("orders_record_course_start_date_time"), dataIndex: "course_start_date_time", key: "course_start_date_time", width:"18%",
            render: (_, record) => (
                <>
                {
                convertDateTime(dayjs(record.course_start_date_time))
                }
                </>
            ),
        },
        {title: t("orders_record_course_price"), dataIndex: "price", key: "price", width:"8%"},
        {title: t("orders_record_payment_time"), dataIndex: "payment_time", key: "payment_time", width:"14%"},
        {
            title: t("orders_record_order_status"), dataIndex: "order_status", key: "order_status", width:"8%",
            render: (_, record) => (
                <>
                {
                    record.order_status === 1 ? t("order_status_1") : 
                    (record.order_status === 2 ? t("order_status_2") : 
                    (record.order_status === 3 ? t("order_status_3") : 
                    (record.order_status === 4 ? t("order_status_4") : "")))
                }
                </>
            ),
        },
        {
            title: t("orders_record_op"), dataIndex: "op", key: "op", width:"8%",
            render: (_, record) => (
                <>
                {
                    (record.order_status === 1 || record.order_status === 2) ?
                <Dropdown
                menu={{
                    items:menuItems.filter(item => {
                        return (item && item.key === "menu_payit" && (record.order_status === 1)) || 
                        (item && item.key === "menu_cancel"  && (record.order_status === 1)) || 
                        (item && item.key === "menu_return"  && (record.order_status === 2))
                    }),
                    onClick: ({key}) => {
                        return handleRowAction(record, key);
                    },
                }}
                >
                    <a onClick={ e => e.preventDefault()}>{t("orders_record_op")}</a>
                </Dropdown>
                : <>{t("no_op")}</>
                }
                </>
            ),
        },
    ];

    const convertDateTime = (value:any) => {
        let _local = value.add(-1 * userStore.zone, 'hour');
        _local = _local.format("YYYY-MM-DD HH:mm");
        let _ret = <>{value.format("YYYY-MM-DD HH:mm")} <br/> {"LO: " + _local }</>;
        return _ret;
    };

    const onDetailItem = (item: OrderDataType) => {
        //console.log("item:", item);
        window.open("/courses/coursedetail/" + item.course_id);
    };

    return(
        <>
        <Row>
            <Form
            layout="inline"
            form={form}
            onFinish={onFinish}
            >
                <Form.Item
                label={t("course_course_name")}
                name="name">
                    <Input
                    placeholder={t("course_placeholder_course_name")}
                    style={{
                        width: 100,
                    }} 
                    />
                </Form.Item>
                <Form.Item
                label={t("course_bought_time")}
                name="bought_time">
                    <Select
                    placeholder={t("course_placeholder_bought_time")}
                    options={[
                        {
                            value: 0,
                            label: '不限',
                        },
                        {
                            value: 1,
                            label: '1月内',
                        },
                        {
                            value: 2,
                            label: '3月内',
                        },
                        {
                            value: 3,
                            label: '半年内',
                        },
                        {
                            value: 4,
                            label: '1年内',
                        },
                    ]}
                    />
                </Form.Item>
                <Form.Item>
                    <Button size="small" type="primary" htmlType="submit">{t("button_search")}</Button>
                    <Button 
                    size="small"
                    style={{
                        margin: '0 8px',
                    }}
                    onClick={() => {
                        onClear();
                    }}
                    >
                        {t("button_reset")}
                    </Button>
                </Form.Item>
            </Form>
        </Row>
        <Row>
            <Col span={24}>
                <div style={{marginTop: "15px"}}>
            <Table
            pagination={{
                position: ["bottomLeft"],
                pageSize: data.page_size,
                current: data.page_number,
                total: data.count,
                showSizeChanger: false,
            }}
            dataSource={data.data} 
            columns={columns} 
            onChange={onChange} />
            </div>
            </Col>
        </Row>
        
        </>
    )

};

export default observer(OrderManagement);