import React from 'react';
import { observer } from "mobx-react-lite";
import { Row, Col, Image, Button} from 'antd';

import { useStore } from '../store';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { ICourse } from '../types';
import AuthService from '../services/auth.services';

type Props = {
    data: ICourse[];
    onEnroll: (e: React.MouseEvent<HTMLElement>, courseId: string | undefined, price: number | undefined) => void;
    onDetailItem: (e: React.MouseEvent<HTMLElement>, courseId: string | undefined) => void;
};

const CourseList = (props: Props) => {
    const { userStore, themeStore } = useStore();
    let {t} = useTranslation();
    const {data, onEnroll, onDetailItem } = props;

    const convertDateTime = (value:any) => {
        let _zone = new Date();
        let zone = _zone.getTimezoneOffset()/60;
        if(AuthService.isAuthed()) {
            zone = -1 * userStore.zone;
        } 
        let _utc_local = value.add(zone, 'hour');
        _utc_local = _utc_local.format("YYYY-MM-DD");
        let _ret = <>{_utc_local}</>
        return _ret;
    };

    return (
        <>
        {
            Array.from(Array(Math.ceil(data.length / 3)).keys()).map( (number, index) => {
                return(
                    <Row key={index}>
                        {
                            data.slice(index * 3, (index * 3) +3).map(m => {
                                return(
                                    <Col span={8} key={m.key} style={{marginTop: '10px', paddingLeft: '5px', paddingRight: '5px'}}>
                                        <Image src={m['brief_picture_url']} style={{height: '160px', textAlign: "center", cursor: "pointer"}} preview={false} 
                                        //alt={m.name}
                                        onClick={(e) => onDetailItem(e, m.key)}
                                        />
                                        <Row>
                                            <Col span={12} style={{fontWeight: "550", marginTop:"5px", marginBottom: "5px", cursor: "pointer"}} onClick={(e) => onDetailItem(e, m.key)}>{m.name}</Col>
                                            <Col span={12} style={{textAlign: "right"}}>{t("com_course_list_teacher")}: {m.teacher_name}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{height: "20px", marginTop:"5px", marginBottom: "5px", fontSize:"12px"}}>{m.brief}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} style={{height: "20px", marginTop:"5px", marginBottom: "5px", fontSize:"12px"}}>{t("com_course_list_start_date")}: {dayjs(m.start_date_time).format("YYYY-MM-DD")}</Col>
                                            <Col span={12} style={{marginTop:"5px", marginBottom: "5px", fontSize:"12px", textAlign:"right"}}>{t("com_course_list_start_date_local")}: {convertDateTime(dayjs(m.start_date_time))}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} style={{color: themeStore.themeColor, fontWeight: "550", }}>
                                                ${m.price}
                                            </Col>
                                            <Col span={12} style={{textAlign:"right"}}>
                                                {/* <Button size="small" type="primary" onClick={(e) => onEnroll(e, m.key, m.discounted)}>{t("com_course_list_enrollment")}</Button> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                );
            })
        }
        </>
    )
};

export default observer(CourseList);