import React from "react";
import { Layout } from "antd";

import './footer.css';

const Footer = () => {

    return (
        <Layout.Footer
        style={{
            backgroundColor:'#fff',
            textAlign: 'center',
        }}
        >
            Chin-up&copy; 2023-2024 Chin-up Technologies Ltd.
        </Layout.Footer>
    )

};

export default Footer;