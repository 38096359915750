import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button } from 'antd';
import {
    MailTwoTone, HomeTwoTone, PhoneTwoTone, FacebookOutlined
  } from '@ant-design/icons';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

type Props = {};

const ContactUs = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();

    useEffect(() => {
        menuStore.setLeftCurrent("/contactus");
    }, []);

    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('contactus_title')}</h3>
            <div style={{textAlign: "left", margin:"0 auto"}}>
            <p>China<br/>
            <MailTwoTone /> {t("contactus_email")} {t("contactus_email_content")}<br/>
            <HomeTwoTone /> {t("contactus_address")} {t("contactus_address_content")}</p>
            <p>Kenya<br/>
            <PhoneTwoTone /> {t("contactus_phone")} {t("contactus_phone_content")}<br/>
            <FacebookOutlined style={{color: '#3f8fff'}}/> {t("contactus_facebook")} <a href="https://www.facebook.com/ChinupKenya/" target="_blank">https://www.facebook.com/ChinupKenya/</a><br/> 
            </p>
            </div>
        </div>
    )
};

export default observer(ContactUs);