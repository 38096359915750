import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from "mobx-react-lite";

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

type Props = {

};

const PaymentResult = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        menuStore.setLeftCurrent("/");
    }, []);

    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('payment_result_title')}</h3>
            {
                location.state.result === "OK" ? <div style={{textAlign: "left", margin: "0 auto", width:"500px"}} dangerouslySetInnerHTML={{__html: t("payment_result_OK") + location.state.orderNo }} />
                : <div style={{textAlign: "left", margin: "0 auto", width:"500px"}} dangerouslySetInnerHTML={{__html: t("payment_result_NOK") }} />
            }
            
        </div>
    )
};

export default observer(PaymentResult);