import { useTranslation } from 'react-i18next';

type Props = {};

const NotFound = (props: Props) => {
    const { t, i18n } = useTranslation();

    return (
    <div style={{marginBottom:"40px", textAlign:"center"}} >
        <h3>{t("no_found")}</h3>
    </div>
    )
}

export default NotFound;
