import api from "./api";
import { IClassroom, IClassroomSearch, ICate, ICateSearch, 
    ICourse, ICourseSearch, ILesson, ILessonSearch,
    IStudentSearch } from "../types";

// cate
const searchCates = (searchParams: ICateSearch) => {
    return api.post("/api/cates", searchParams);
};

const createCate = (newCate: ICate) => {
    return api.post("/api/cate", newCate);
};

const updateCate = (cate: ICate) => {
    return api.put("/api/cate/" + cate.id, cate);
};

const deleteCate = (cate_id: bigint) => {
    return api.delete("/api/cate/" + cate_id);
};

const fetchCateById = (cate_id: bigint) => {
    return api.get("/api/cate/" + cate_id);
};

// course
const searchCourses = (searchParams: ICourseSearch) => {
    return api.post("/api/courses", searchParams);
};

const createCourse = (newCourse: ICourse) => {
    return api.post("/api/course", newCourse);
};

const updateCourse = (course: ICourse) => {
    return api.put("/api/course/" + course.id, course);
};

const deleteCourse = (course_id: bigint) => {
    return api.delete("/api/course/" + course_id);
};

const fetchCourseById = (course_id: bigint) => {
    return api.get("/api/course/" + course_id);
};

// classroom
const searchClassrooms = (searchParams: IClassroomSearch) => {
    return api.post("/api/classrooms", searchParams);
};

const createClassroom = (newClassroom: IClassroom) => {
    return api.post("/api/classroom", newClassroom);
};

const updateClassroom = (classroom: IClassroom) => {
    return api.put("/api/classroom/" + classroom.id, classroom);
};

const deleteClassroom = (classroom_id: bigint) => {
    return api.delete("/api/classroom/" + classroom_id);
};

const fetchClassroomById = (classroom_id: bigint) => {
    return api.get("/api/classroom/" + classroom_id);
};

// lesson
const searchLessons = (searchParams: ILessonSearch) => {
    return api.post("/api/lessons", searchParams);
};

const createLesson = (newLesson: ILesson) => {
    console.log(newLesson)
    return api.post("/api/lesson", newLesson);
};

const updateLesson = (lesson: ILesson) => {
    return api.put("/api/lesson/" + lesson.id, lesson);
};

const deleteLesson = (lesson_id: bigint) => {
    return api.delete("/api/lesson/" + lesson_id);
};

const fetchLessonById = (lesson_id: bigint) => {
    return api.get("/api/lesson/" + lesson_id);
};

// student
const searchStudents = (searchParams: IStudentSearch) => {
    return api.post("/api/students", searchParams);
};

// homepage
const fetchHomepageCourses = () => {
    return api.get("/api/homepage/courses");
};

const fetchHomepageCarousels = () => {
    return api.get("/api/homepage/carousels");
};

const CourseService = {
    createCate,
    updateCate,
    deleteCate,
    searchCates,
    fetchCateById,
    
    searchCourses,
    createCourse,
    updateCourse,
    deleteCourse,
    fetchCourseById,

    createClassroom,
    updateClassroom,
    deleteClassroom,
    searchClassrooms,
    fetchClassroomById,

    searchLessons,
    createLesson,
    updateLesson,
    deleteLesson,
    fetchLessonById,

    searchStudents,

    fetchHomepageCourses,
    fetchHomepageCarousels,
};

export default CourseService;