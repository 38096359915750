import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, App } from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import CourseService from '../../services/courses.services';

type Props = {};

const OrderCourse = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();

    const { course_id } = useParams();
    const [course, setCourse] = useState();

    useEffect(() => {
        menuStore.setLeftCurrent("/courses/list");
    }, []);

    useEffect(() => {
        let isUnmount = false;

        const fetchData = (course_id:string) => {
            if(course_id === undefined || course_id ===null || parseInt(course_id) <=0 ) {
                message.error(t("err_need_course_id"));
                return;
            }
            CourseService.fetchCourseById(BigInt(course_id))
            .then(response => {
                // console.log(response)
                if(response.data.status === 200) {
                    setCourse(response.data.data);
                }
            })
            .catch(error => {
                message.error(t("err_failed"));
            })
        }

        if(course_id) {
            fetchData(course_id);
        }

        return () => {
            isUnmount = true;
        }
    }, []);

    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('课程报名确认')}</h3>
        </div>
    )
};

export default observer(OrderCourse);