import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, Carousel, Row, Col, App } from 'antd';
import type { CarouselProps } from 'antd';
import { SketchSquareFilled } from '@ant-design/icons';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import CourseService from '../../services/courses.services';
import AuthService from '../../services/auth.services';
import OrderService from '../../services/orders.services';

import CourseList from '../../components/courselist';
import NiceModal from '@ebay/nice-modal-react';
import Login from '../account/login';

import { ICourse, IOrder } from '../../types';

type Props = {};

type ICourseList = {
    id: string,
    name: string,
    data: ICourse[],
};

type ICarouselImage = {
    id: string,
    key: string,
    title?: string,
    image_url?: string,
    url?: string
}

const Home = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();
    const navigate = useNavigate();

    useEffect(() => {
      menuStore.setLeftCurrent("/");
    }, []);

    const [images, setImages] = useState<ICarouselImage[]>([]);
    useEffect(() => {
        CourseService.fetchHomepageCarousels()
        .then(response => {
            if(response.data.status === 200) {
                setImages(response.data.data);
                return;
            }
        })
        .catch(error => {
            message.error(t('carousel_error_fetch_carousel'));
        })
    }, []);

    const contentStyle: React.CSSProperties = {
        height: '168px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const onImageClick = (url: string | undefined) => {
        if(url) {
            window.open(url);
        } else {
            message.error(t("can_not_open_url"));
        }
        // console.log(url)
    };

    const [data, setData] = useState<ICourseList[]>([]);
    useEffect(() => {
        CourseService.fetchHomepageCourses()
        .then(response => {
            if(response.data.status === 200) {
                setData(response.data.data);
                return;
            }
        })
        .catch(error => {
            message.error(t('course_error_fetch_course'));
        })
    }, []);

    // enrollment
    const onEnroll = (e: React.MouseEvent<HTMLElement>, courseId: string | undefined, price: number | undefined) => {
        // console.log(e, courseId, price);
        if(courseId && price) {
            if(!AuthService.isAuthed()) {
                //NiceModal.show(Login, {forwardPath: "/courses/ordercourse/" + courseId});
                NiceModal.show(Login, {forwardPath: "/"});
            } else {
                // place order
                placeOrder(courseId, price);
                // checkOut(courseId, price);
            }
        }
    };

    const placeOrder = (courseId: string, price: number) => {
        let _order: IOrder = {
            course_id: courseId,
            price: price,
            buyer_id: userStore.userId.toString()
        }
        //console.log(_order)
        OrderService.createOrder(_order)
        .then(response=> {
            console.log(response)
            if(response.data.status === 200 && response.data.code ===0) {
                message.success(t("enrollment_enroll_successful"));
                navigate("/console/checkout/" + response.data.data['order_id']);
            }
            if(response.data.status === 200 && response.data.code ===2) {
                message.error(t("enrollment_enroll_already_enrolled"));
            }
            return;
        })
        .catch(error => {
            //console.log(error);
            message.error(t("enrollment_enroll_failed"))
        });
    };
    
    const onDetailItem = (e: React.MouseEvent<HTMLElement>, courseId: string | undefined ) => {
        // console.log(e, courseId)
        if(courseId) {
            window.open("/courses/coursedetail/" + courseId);
        }
    };

    const onMore = (e: React.MouseEvent<HTMLElement>, cateId: string) => {
        // console.log(cateId);
        if(cateId) {
            navigate("/courses/list/?cateid=" + cateId);
        }
    };

    return (
        <div style={{marginTop:"10px", marginBottom:"20px"}}
        >
            <Row>
                <Col span={24}>
                <Carousel autoplay>
                    {
                        images && images.map( (img, index) => (
                            <div style={contentStyle} key={index}>
                                <a onClick={(e) => onImageClick(img.url)}><img src={img.image_url} height="360"></img></a>
                            </div>
                        ) )
                    }
                </Carousel>
                </Col>
            </Row>
            {
                data && data.map( m => (
                    <div key={m['id']} style={{marginTop: "15px", marginBottom: "20px", borderWidth: "1px 0 0 0", borderStyle:"solid", borderColor: "lightgray"}}>
                        <Row style={{marginTop: "5px"}}>
                            <Col span={12} style={{fontWeight: "600", fontSize: "16px" }}>
                            <SketchSquareFilled style={{color: themeStore.themeColor}} /> {m['name']}
                            </Col>
                            <Col span={12} style={{textAlign: "right"}}>
                                { parseInt(m['id']) > 0 ? <a onClick={(e) => { onMore(e, m['id'])}}>{t("more")}</a> : <></>}
                            </Col>
                        </Row>
                        <CourseList data={m['data']} onEnroll={onEnroll} onDetailItem={onDetailItem} key={m['id']} />
                    </div>
                ))
            }

        </div>
    )
};

export default observer(Home);