import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

//import enUsTrans from "../public/locales/en_us.json";
//import zhCnTrans from "../public/locales/zh_cn.json";

i18n.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    /* resources: {
      en: {
        translation: enUsTrans,
      },
      zh: {
        translation: zhCnTrans,
      },
    }, */
    //lng: "zh",
    fallbackLng: "en",
  });

export default i18n;