import React, {useState, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import CourseService from '../../services/courses.services';

type Props = {
    course_id: string;
};

type LessonItemType = {
    key: string;
    id: string;
    name: string;
    class_date_time: string;
    classroom_name: string;
    classroom_url: string;
};

const LessonList = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {course_id} = props;

    const [lessons, setLessons] = useState([]);
    useEffect(() => {
        if(!course_id) {
            return;
        }
        let _param = {
            course_id: course_id,
            page_number: 0,
            page_size: 20,
        }
        CourseService.searchLessons(_param)
        .then(response => {
            if(response.data.status === 200) {
                if(response.data.code === 0) {
                    // console.log(response.data.data.data);
                    setLessons(response.data.data.data)
                }
            }
        })
        .catch();
    }, [course_id]);

    const convertDateTime = (value:any) => {
        let _utc_local = value.add(-1 * userStore.zone, 'hour');
        _utc_local = _utc_local.format("YYYY-MM-DD HH:mm");
        let _ret = <>{_utc_local }</>;
        return _ret;
    };

    return(
        <>
        {
            lessons && lessons.length > 0 ? 
            <table>
                <tbody>
                    {
                        lessons.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{t("lesson_name")}: </td><td>{item['name']}</td>
                                    <td>{t("lesson_date_time")}: </td><td>{dayjs(item['class_date_time']).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{t("lesson_date_time_local")}: </td><td>{convertDateTime(dayjs(item['class_date_time']))}</td>
                                </tr>
                            )
                        }) 
                    }
                </tbody>
            </table>
            : 
            <> 
            {t("no_data")}
            </>
        }
        </>
    )

};

export default observer(LessonList);