import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, ColorPicker, } from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
    style: React.CSSProperties;
};

const Clock = (props: Props) => {
    const [now, setNow] = useState(dayjs());

    useEffect(()=> {
        const t = setInterval(() => {
            setNow(dayjs())
        }, 1000)

        return () => {
            clearTimeout(t)
        }
    }, [])

    return (
        <>
        <span style={props.style}>{dayjs.tz.guess()}  {now.format('HH:mm:ss')} </span>
        </>
    )
};

export default observer(Clock);