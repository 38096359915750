import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import Navbar from "./navbar";
import Footer from "./footer";

type Props = {};

const MainLayout = (props: Props) => {
    
    return (
        <Layout 
        style={{
            backgroundColor: '#fff',
            width: '960px',
            margin: "auto"
        }}>
            <Navbar />
            <Layout.Content
            style={{
                backgroundColor:'#fff',
                marginLeft: "50px",
                marginRight: "50px"
            }}
            >
                <Outlet />
            </Layout.Content>
            <Footer />
        </Layout>
    )

};

export default MainLayout;