import api from "./api";
import { IOrder, IOrderSearch, IBoughtCourseSearch } from "../types";

// cate
const searchOrders = (searchParams: IOrderSearch) => {
    return api.post("/api/orders", searchParams);
};

const createOrder = (newOrder: IOrder) => {
    return api.post("/api/order", newOrder);
};

const updateOrderPayment = (order: IOrder) => {
    return api.put("/api/order/payment/" + order.id, order);
};

const updateOrderPayment2 = (order: IOrder) => {
    return api.put("/api/order/payment2/" + order.id, order);
};

const updateOrderStatus = (order: IOrder) => {
    return api.put("/api/order/status/" + order.id, order);
};

const fetchOrderById = (order_id: bigint) => {
    return api.get("/api/order/" + order_id);
};

const searchBoughtCourses = (searchParams: IBoughtCourseSearch) => {
    return api.post("/api/bougthcourses", searchParams);
};

const OrderService = {
    createOrder,
    updateOrderPayment,
    updateOrderPayment2,
    updateOrderStatus,
    searchOrders,
    fetchOrderById,
    searchBoughtCourses,
};

export default OrderService;