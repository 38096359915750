import React, {useState, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import { Form, Select, Input, Button, Row, Col, App} from "antd";
import { useNavigate } from "react-router-dom";

import { useStore } from '../../store';
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.services";

import { IRegister } from "../../types";

type Props = {};

const Register = (props: Props) => {
    const navigate = useNavigate();
    const { userStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const [form] = Form.useForm();
    const {message} = App.useApp();

    useEffect(() => {
        menuStore.setRightCurrent("/register");
    }, []);

    const onFinish = (values: IRegister) => {
        //console.log(values);
        AuthService.register(values)
        .then(response => {
            // console.log(response)
            if(response.status === 202) {
                message.error(t("register_msg_account_exist"));
                return;
            }
            if(response.status === 400) {
                message.error(t("register_msg_bad_request"));
                return;
            }
            if(response.status === 200 && response.data.code === 0) {
                message.success(t('register_msg_account_created'));
                alert(t('register_msg_account_created_alert'));
                navigate('/console');
            } else {
                message.error(t('register_msg_requestion_failed'));
            }
        })
        .catch(err => {
            message.error(t('register_msg_requestion_failed'));
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("failed:", error);
        alert(t("register_validation_msg"));
    };

    const onClear = () => {
        form.resetFields();
    };

    const countryCodes = [
        { value: "86", label: "+86"},
        { value: "254", label: "+254"}
    ];

    const prefixSelector = (
        <Form.Item name="country_code" noStyle>
          <Select
            style={{
              width: 90,
            }}
            options={countryCodes}
          >
          </Select>
        </Form.Item>
    );
    
    return (
        <Row>
            <Col span={24}>
            <div style={{width:"500px", margin: "0 auto", paddingTop: 60}}>
            <div>
            <div style={{textAlign: "center", fontSize: 24}}><div style={{height: "46px", textAlign:"center", fontSize:"24px", fontWeight:"500"}}></div></div>
            <div style={{textAlign: "center", paddingTop: 20, paddingBottom:20, fontSize: 18}}>{t("register_title")}</div>
            <Form
            name="RegisterForm"
            labelCol={{span: 8}}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            //layout="vertical"
            form={form}
            initialValues={{
                country_code: "254"
            }}
            >
                    <Form.Item
                    label={t("register_mobile")}
                    name="account"
                    //noStyle
                    rules={[
                        {
                            required: true,
                            message: t("register_account_err_msg"),
                        },
                        {
                            //pattern:/^1[0-9]\d{9}$/,
                            pattern:/^\d{6,18}$/,
                            message: t("register_account_err_format_msg")
                        }
                    ]}
                    >
                        <Input
                        addonBefore={prefixSelector}
                        placeholder={t("register_account_placeholder_msg")}
                        />
                    </Form.Item>
                    <Form.Item 
                    label={t("register_password")}
                    name="password"
                    //noStyle
                    rules={[
                        {
                            required: true,
                            type: "regexp",
                            pattern: new RegExp(/\d+/g),
                            message: t("register_password_err_msg"),
                        },
                        {
                            min: 8,
                            max: 12,
                            message: t("register_password_err_format_msg")
                        }
                    ]}
                    >
                        <Input.Password 
                        placeholder={t("register_password_placeholder_msg")} />
                    </Form.Item>
                    <Form.Item 
                    label={t("register_password_confirm")}
                    name="password2"
                    //noStyle
                    rules={[
                        {
                            required: true,
                            type: "regexp",
                            pattern: new RegExp(/\d+/g),
                            message: t("register_password_err_msg"),
                        },
                        {
                            min: 8,
                            max: 12,
                            message: t("register_password_placeholder_msg")
                        },
                        {
                            validator(_, value) {
                                if(value && value === form.getFieldValue("password")) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(t("register_password_confirm_not_same"));
                                }

                            },
                        }
                    ]}
                    >
                        <Input.Password 
                        placeholder={t("register_password_placeholder_msg")} />
                    </Form.Item>
                    <Form.Item 
                    label={t("register_username")}
                    name="username"
                    //noStyle
                    rules={[
                        {
                            required: true,
                            type: "regexp",
                            pattern: new RegExp(/\d+/g),
                            message: t("register_username_err_msg"),
                        },
                        {
                            min: 5,
                            max: 20,
                            message: t("register_username_err_format_msg")
                        }
                    ]}
                    >
                        <Input 
                        placeholder={t("register_username_placeholder_msg")} />
                    </Form.Item>
                    <Form.Item 
                    label={t("register_email")}
                    name="email"
                    //noStyle
                    rules={[
                        {
                            required: true,
                            message: t("register_email_err_msg"),
                        },
                        {
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t("register_email_err_format_msg")
                        }
                    ]}
                    >
                        <Input
                        placeholder={t("register_email_placeholder_msg")}/>
                    </Form.Item>
                    <Form.Item
                        label={t("user_zone")}
                        name="zone"
                        rules={[
                            {
                                required: true,
                                message: t("user_msg_zone")
                            }
                        ]}
                        >
                            <Select 
                            placeholder={t("user_palceholder_zone")}
                            options={[
                                {
                                    value: 0,
                                    label: t('user_zone_utc'),
                                },
                                {
                                    value: 3,
                                    label: t('user_zone_utc_3'),
                                },
                                {
                                    value: 8,
                                    label: t('user_zone_utc_8'),
                                },
                              ]}
                            />
                        </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                    <Button
                    style={{
                        width: '100px'
                    }}
                    onClick={() => {
                        onClear();
                    }}>
                        {t("register_cancel")}
                    </Button>
                    <Button 
                    style={{
                        margin: '0 20px',
                        width: '100px'
                    }}
                    type="primary" htmlType="submit">
                      {t("register_register")}
                    </Button>
                </Form.Item>
            </Form>
            </div>
            <br />
        </div> 
            </Col>
        </Row>
    )
};

export default observer(Register);