import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './routes/authcontext';
import { StoreProvider } from './store';
import { App, ConfigProvider } from 'antd';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import NiceModal from '@ebay/nice-modal-react';

import Router from './routes';

import { observer } from "mobx-react-lite";
import { useStore } from './store';

// import './App.css';
const initialOptions = {
  clientId: "AQ_Y1F4Nw2CWDApatEPcmIP115jrbqTUnkaa59gdKkayq0N5c_DFM1z7xswHnpRQo5TCAiVzpqb4TeBq",
  currency: "USD",
  intent: "capture",
};

function DApp() {

  const { themeStore } = useStore();

  return (
    <App>
    <BrowserRouter>
      <StoreProvider>
        <ConfigProvider
        theme={{
          token: {
            colorPrimary: themeStore.color,
          },
        }}
        >
          <PayPalScriptProvider options={initialOptions}>
            <AuthProvider>
              <NiceModal.Provider>
                <Router />
              </NiceModal.Provider>
            </AuthProvider>
          </PayPalScriptProvider>
        </ConfigProvider>
      </StoreProvider>
    </BrowserRouter>
    </App>
  );
}

export default observer(DApp);
