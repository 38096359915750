import React, {ReactNode} from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./authcontext";

import Home from "../pages/home";

import Courses from "../pages/courses";
import CourseDetail from "../pages/courses/coursedetail";
import OrderCourse from "../pages/courses/ordercourse";

import AboutUs from "../pages/aboutus";
import ContactUs from "../pages/contactus";

import Register from "../pages/account/register";

import MainLayout from "../layout/mainlayout";
import Console from "../pages/console";
import CheckOut from "../pages/console/checkout";
import CheckOut2 from "../pages/home/checkout2";
import PaymentResult from "../pages/home/paymentresult";

import LoginPage from "../pages/account/loginpage";
import NotFound from "./notfound";
import Profile from "../pages/account/profile";

type Props = {
    children?: ReactNode;
};

export const PrivateRoute = ({children}: Props) => {
    const { user } = useAuth();

    if(!user) {
        return <Navigate to='/' replace />;
    }

    // return <>{children}<Outlet /></>;
    return <>{children}</>;
}

/*
const PrivateRoutes = () => {
    const {authenticated } => useContext(AuthContext)

    if(!authenticated) return <Navigate to='/login' replace />
    return <Outlet />

}
*/

const Router = (props: {}) => {
    return(
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/register' element={<Register />} />
                <Route path='' element={<Home />} />
                <Route path='courses/list' element={<Courses />} />
                <Route path='courses/coursedetail/:course_id' element={<CourseDetail />} />
                <Route path='courses/ordercourse/:course_id' element={<OrderCourse />} />
                <Route path='/checkout2/:order_id' element={<CheckOut2 />} />
                <Route path='/paymentresult' element={<PaymentResult />} />
                <Route path='aboutus' element={<AboutUs />} />
                <Route path='contactus' element={<ContactUs />} />
                <Route path='console/*' element={
                    <PrivateRoute>
                        <Routes>
                            <Route path='' element={<Console />} />
                            <Route path='/checkout/:order_id' element={<CheckOut />} />
                            <Route path='/profile' element={<Profile />} />
                        </Routes>
                    </PrivateRoute>
                } />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
};

export default Router;
