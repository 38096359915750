import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Descriptions, App, } from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import AuthService from '../../services/auth.services';

type Props = {};

const Profile = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();

    useEffect(() => {
        menuStore.setLeftCurrent("");
    }, []);

    const [data, setData] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        AuthService.fetchProfile(userStore.user_id.toString())
        .then(response => {
            if(response.data.status === 200 && response.data.code === 0) {
                setData(response.data.data);
            } else {
                message.error(t("profile_fetch_profile_error"));
            }
        })
        .catch( () => {
            message.error(t("profile_fetch_profile_error"));
        })
    };

    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('profile_title')}</h3>
            <div style={{textAlign:"left"}}>
                <Descriptions title={t("profile_account_info")} column={1} >
                    <Descriptions.Item label={t("profile_username")}>{data && data['account']['user_name']}</Descriptions.Item>
                    <Descriptions.Item label={t("profile_phone")}>{data && (data['account']['country_code'] + " " + data['account']['phone'])}</Descriptions.Item>
                    <Descriptions.Item label={t("profile_email")}>{data && data['account']['email']}</Descriptions.Item>
                    <Descriptions.Item label={t("profile_role")}>{data && data['account']['role'] === 1 ? t("profile_platform_role"): t("profile_normal_role")}</Descriptions.Item>
                    <Descriptions.Item label={t("profile_register_time")}>{data && data['account']['created_at']}</Descriptions.Item>
                    <Descriptions.Item label={t("profile_time_zone")}>UTC{data && data['account']['zone']<=0?"":"+"}{data && data['account']['zone']}</Descriptions.Item>
                </Descriptions>
                {
                    data && data['teacher'] ? 
                    <Descriptions title={t("profile_teacher_info")} column={1} >
                        <Descriptions.Item label={t("profile_teacher_name")}>{data && data['teacher']['name']}</Descriptions.Item>
                        <Descriptions.Item label={t("profile_teacher_brief")}>{data && data['teacher']['brief']}</Descriptions.Item>
                        <Descriptions.Item label={t("profile_teacher_gender")}>{data && data['teacher']['gender'] === 1 ? t("profile_teacher_gender_male"): t("profile_teacher_gender_female")}</Descriptions.Item>
                        <Descriptions.Item label={t("profile_tacher_create_time")}>{data && data['teacher']['created_at']}</Descriptions.Item>
                    </Descriptions>
                    : ""
                }
            </div>
            
        </div>
    )
};

export default observer(Profile);