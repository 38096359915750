import React, {useState} from "react";
import type { MenuProps } from "antd";
import { Layout, Menu, Row, Col, ColorPicker, Button, Dropdown, message } from "antd";
import { useNavigate } from "react-router-dom";

import { UserOutlined, MailTwoTone} from '@ant-design/icons';
import { Color } from "antd/es/color-picker";

import { ColorPickerIcon, LangIcon } from "../../assets/icons";
import { useStore } from "../../store";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../routes/authcontext";

import NiceModal from "@ebay/nice-modal-react";
import Login from "../../pages/account/login";
import Password from "../../pages/account/password";

import Clock from "../../components/clock";

import './navbar.css';

const Navbar = () => {

    const navigate = useNavigate();
    const { userStore, themeStore, menuStore } = useStore();
    const { t, i18n } = useTranslation();
    const {logout} = useAuth();

    // left menu, no login needed
    const menu_item_left: MenuProps['items'] = [
        {label: t('home'), key: '/'},
        {label: t('courses'), key: '/courses/list'},
        {label: t('aboutus'), key: '/aboutus'},
        {label: t('contactus'), key: '/contactus'}
    ];

    // right menu, before login
    // const menu_item_right: MenuProps['items'] = [
    //     {label: t('register'), key: '/register'},
    //     {label: t('login'), key: '/login'}
    // ];

    // right menu, after login
    const menu_profile: MenuProps['items'] = [
        {
            label: t('console'), key: '/console'
        },
        {
            title: 'U',
            /* label: 'UI', */
            key: 'submenu',
            icon: <UserOutlined />,
            children: [
                {label: t('profile'), key: '/console/profile'},
                {label: t('changepasswd'), key: '/changepasswd'},
                {label: t('logout'), key: '/logout'},
            ]
        }
    ];

    // must named as items
    const items: MenuProps['items'] = [
        { label: t('chinese'), key: 'zh'},
        { label: t('english'), key: 'en'},
    ];

    const onLeftMenuClick: MenuProps['onClick'] = (e) => {
        // console.log(e);
        menuStore.setRightCurrent('');
        if (e && e.key) {
            navigate(e.key);
            menuStore.setLeftCurrent(e.key);
        } else {
            message.error(t('menunaverror'));
        }
    };

    const onRightMenuClick: MenuProps['onClick'] = (e) => {
        if (e && e.key) {
            menuStore.setLeftCurrent('');
            menuStore.setRightCurrent(e.key);
            //console.log(e.key)
            if(e.key === '/login') {
                onLogin();
            }
            if(e.key === '/logout') {
                onLogout();
            }
            if(e.key === '/changepasswd') {
                onChangePassword();
            }
            if(e.key === '/register') {
                navigate('/register');
            }
            if(e.key === '/console') {
                navigate('/console')
            }
            if(e.key === '/console/profile') {
                navigate('/console/profile')
            }
        } else {
            message.error(t('menunaverror'));
        }
    };

    // must named as onClick
    const onClick: MenuProps['onClick'] = (e) => {
        let lang = e.key;
        i18n.changeLanguage(lang);
    };

    // change theme color
    const onColorClick = (value: Color) => {
        // console.log(value.toHexString());
        themeStore.setThemeColor(value.toHexString());
        localStorage.setItem('theme_color', value.toHexString());
    };

    const onLogin = () => {
        NiceModal.show(Login, {forwardPath: '/console'});
        //menuStore.setLeftCurrent('');
        //menuStore.setRightCurrent('/console');
    };

    const onLogout = () => {
        logout();
        //menuStore.setLeftCurrent('/');
        //menuStore.setRightCurrent('');
        userStore.setAccessToken(null);
        navigate("/");
    };

    const onChangePassword = () => {
        NiceModal.show(Password);
    };

    return(
        <>
        <div style={{height: '32px', paddingLeft: "50px", paddingRight: "50px", borderTopWidth: "1px"}}>
            <div style={{width: '50%', float: 'left', lineHeight:"30px"}}><MailTwoTone /> {/*t("nav_contact_us")*/}: info@chin-up.net</div>
            <div style={{textAlign: 'right', width: '50%', float: 'left'}}>
                <Clock style={{lineHeight: "30px"}} />
                <div style={{float: 'right'}}>
                    <ColorPicker size="small"  onChangeComplete={onColorClick} >
                        <Button icon={<ColorPickerIcon style={{width:'18px', height: '18px', marginTop: '2px'}} />} ghost /> 
                    </ColorPicker>
                </div>
                <div style={{float: 'right'}}>
                    <Dropdown 
                    placement="top"
                    menu={{items, onClick, selectable: true, defaultSelectedKeys: [i18n.language] }} 
                    trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}><Button icon={<LangIcon style={{width:'18px', height: '18px', marginTop: '2px'}} />} ghost /></a>
                    </Dropdown>
                </div>
            </div>
            <div style={{clear: "both"}}></div>
        </div>
        <Layout.Header
        style={{
            backgroundColor:'#fff',
        }}
        >
            <Row>
                <Col span={24} className="menuItemStyle">
                <div style={{float:'left'}}><img src="/logo.png" height="40" /></div>
                <div>
                <Menu
                onClick={onLeftMenuClick}
                selectedKeys={[menuStore.leftCurrent]}
                mode="horizontal"
                items={menu_item_left}
                />
                </div>
                </Col>
                <Col 
                span={0} 
                className="menuItemStyle"
                style={{textAlign: "right"}}
                >
                    {/*
                    <div style={{float: 'right'}}>
                        <ColorPicker size="small"  onChangeComplete={onColorClick} >
                        <Button icon={<ColorPickerIcon style={{width:'18px', height: '18px', marginTop: '2px'}} />} ghost /> 
                        </ColorPicker>
                    </div>
                    
                    <div style={{float: 'right'}}>
                        <Dropdown 
                        placement="top"
                        menu={{items, onClick, selectable: true, defaultSelectedKeys: [i18n.language] }} 
                        trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}><Button icon={<LangIcon style={{width:'18px', height: '18px', marginTop: '2px'}} />} ghost /></a>
                        </Dropdown>
                    </div>
                    */}
                    <div>
                    <Menu mode="horizontal"
                    style={{display: "block"}}
                    onClick={onRightMenuClick}
                    selectedKeys={[menuStore.rightCurrent]}
                    //items={userStore.access_token ? menu_profile : menu_item_right}
                    />
                    </div>  
                </Col>
            </Row>
        </Layout.Header>
        </>
    );
};

export default Navbar;