import api from "./api";
import { IStudyCanlendarSearch, ITeachCanlendarSearch } from "../types";

// canlendar
const searchStudyCalendar = (searchParams: IStudyCanlendarSearch) => {
    return api.post("/api/canlendar/studylessons", searchParams);
};

const searchTeachCalendar = (searchParams: ITeachCanlendarSearch) => {
    return api.post("/api/canlendar/teachlessons", searchParams);
};

const CanlendarService = {
    searchStudyCalendar,
    searchTeachCalendar,
};

export default CanlendarService;