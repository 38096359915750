// 页面主题颜色
import { makeAutoObservable } from "mobx";

class Theme {
    color: string;

    constructor () {
        let _saved = localStorage.getItem('theme_color');
        this.color = _saved ? _saved : '#2994F6';
        makeAutoObservable(this);
    };

    get themeColor () {
        return this.color;
    };

    setThemeColor (name: string) {
        this.color = name;
    };
};

const themeStore = new Theme();

export default themeStore;