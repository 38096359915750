import axios, {AxiosRequestConfig} from "axios";
import { access } from "fs";

let BASEURL = ""

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASEURL = "http://localhost:8008";
    //BASEURL = "http://101.34.180.244:8001";
} else {
    BASEURL = "http://104.168.125.195:8008";
}

const axiosInstance = axios.create({
    baseURL: BASEURL,
    timeout: 3000,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token; 
            //config.headers["x-access-token"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// for token expired and refresh token
interface PendingTask {
    config: AxiosRequestConfig
    resolve: Function
}
let refreshing = false;
const queue: PendingTask[] = [];

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        let { config } = err;

        if(refreshing) {
            return new Promise((resolve) => {
                queue.push({
                    config,
                    resolve
                });
            });
        }

        const originalConfig = err.config;

        if (originalConfig.url !== "/auth" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && err.response.data.reasons[0] === "Signature has expired." && !originalConfig._retry) {
                originalConfig._retry = true;
                refreshing = true;

                try {
                    const rs = await axiosInstance.post("/auth/refresh", {
                        refresh_token: localStorage.getItem('refresh_token'),
                    });
                    if (rs.status === 200) {
                        const { access_token } = rs.data;
                        localStorage.setItem('access_token', access_token);
                        //localStorage.setItem('refresh_token', refresh_token);
                        //return axiosInstance(originalConfig);
                        queue.forEach(({config, resolve}) => {
                            resolve(axiosInstance(config))
                        })
                        return axiosInstance(originalConfig);
                    } else {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_tooken');
                        localStorage.removeItem('me');
                        window.location.href = "/";
                        alert("Login expired, please login again.");
                    }
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            if (err.response.status === 401 && err.response.data.reasons[0] === "Authentication failed." && !originalConfig._retry) {
                // try but failed
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_tooken');
                localStorage.removeItem('me');
                window.location.href = "/";
                alert("Login expired, please login again.");
            }
        }
        return Promise.reject(err);
    }
);

/*
async function refreshToken() {
    const res = await axiosInstance.post('/auth/refresh', {
        refresh_token: localStorage.getItem('refresh_token')
    });
    const { access_token, refresh_token } = res.data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    return res;
};
*/

export default axiosInstance;