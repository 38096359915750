import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, ColorPicker, } from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

type Props = {};

const AboutUs = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();

    useEffect(() => {
        menuStore.setLeftCurrent("/aboutus");
    }, []);

    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('aboutus_title')}</h3>
            <div style={{textAlign: "left", margin: "0 auto"}} dangerouslySetInnerHTML={{__html: t("aboutus_content") }} />
            <br/>
            <h4 style={{textAlign: "left", margin: "0 auto"}}>{t("academic_exchange")}</h4>
            <p>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034040070.jpg" width={500}/>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034050734.jpg" width={500}/>
            </p>
            <br/>
            <h4 style={{textAlign: "left", margin: "0 auto"}}>{t("methodology")}</h4>
            <p>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034587519.jpg" width={500}/>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034548412.jpg" width={500}/>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034557748.jpg" width={500}/>
                <img src="https://chin-up-classrooms.oss-us-west-1.aliyuncs.com/lessons/1711034566021.jpg" width={500}/>
            </p>
        </div>
    )
};

export default observer(AboutUs);