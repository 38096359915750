import { createContext, ReactNode, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { IUser, IAuthContext } from "../types";

type Props = {
    children?: ReactNode;
};

/*
type IAuthContext = {
    authenticated: boolean;
    setAuthenticated: (newState: boolean) => void
};

const initialValue = {
    authenticated: false,
    setAuthenticated: () => {}
};
*/

const initialValue = {
    user: null,
    login: () => {},
    logout: () => {}
};

const AuthContext = createContext<IAuthContext>(initialValue);

export const AuthProvider = ({children}: Props) => {

    const [user, setUser] = useLocalStorage<IUser | null>("user", null);
    //const [authenticated, setAuthenticated] = useState(initialValue.authenticated);
    const navigate = useNavigate();

    const login = async (data:IUser) => {
        setUser(data);
        navigate('/home');
    };

    const logout = async () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        navigate('/');
    };

    const value = useMemo(() => ({
        user,
        login,
        logout
    }), [user]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

    /*
    return(
        <AuthContext.Provider value={{authenticated, setAuthenticated}}>
            {children}
        </AuthContext.Provider>
    ) */
};

//export {AuthContext, AuthProvider};
export const useAuth = () => {
    return useContext(AuthContext);
};