import { createContext, useContext, ReactNode, useMemo } from "react";
import userStore  from "./user";
import themeStore from "./theme";
import menuStore from "./menu";
import cateStore from "./cate";
import classroomStore from "./classroom";

type Props = {
    children?: ReactNode;
};

export const StoreContext = createContext({ 
    userStore, 
    themeStore,
    menuStore,
    cateStore,
    classroomStore,
 });

export const StoreProvider = ({children}: Props) => {

    const value = useMemo(() => ({
        userStore,
        themeStore,
        menuStore,
        cateStore,
        classroomStore,
    }), [userStore, themeStore, menuStore, cateStore, classroomStore]);

    return <StoreContext.Provider value={value} >
        {children}
        </StoreContext.Provider>
};

export const useStore = () => {
    return useContext(StoreContext);
}
