import { makeAutoObservable } from "mobx";

// user is the combine of access_token and user
class User {
    access_token: string | null;
    user_name: string;
    user_id: bigint;
    account: string;
    email: string;
    role: number;
    zone: number;
    country_code: string;

    constructor () {
        this.access_token = localStorage.getItem('access_token');
        this.user_name = '';
        this.user_id = BigInt(0);
        this.account = '';
        this.email = '';
        this.role = 0;
        this.zone = 0;
        this.country_code = '';

        let _me = localStorage.getItem('user');
        if (_me) {
            try {
                let me = JSON.parse(_me);
                this.user_name = me.userName;
                this.user_id = BigInt(me.userId);
                this.account = me.account;
                this.email = me.email;
                this.role = me.role;
                this.zone = me.zone;
                this.country_code = me.country_code;
            } catch {
                let me = null;
            }
        }
        makeAutoObservable(this);
    };

    get accessToken () {
        return this.access_token;
    };

    setAccessToken (token: string | null) {
        this.access_token = token;
        if (token) {
            localStorage.setItem('access_token', token);
        }
    };

    get userName () {
        return this.user_name;
    };

    setUserName (name: string) {
        this.user_name = name;
    };

    get userId () {
        return this.user_id;
    };

    setUserId (id: bigint) {
        this.user_id = id;
    };

    get Account () {
        return this.account;
    };

    setAccount (account: string) {
        this.account = account;
    };

    get Email () {
        return this.email;
    };

    setEmail (email: string) {
        this.email = email;
    };

    get Role () {
        return this.role;
    };

    setRole (role: number) {
        this.role = role;
    };

    get Zone () {
        return this.zone;
    };

    setZone (zone: number) {
        this.zone = zone;
    };

    get CountryCode () {
        return this.country_code;
    };

    setCountryCode (country_code: string) {
        this.country_code = country_code;
    };
};

const userStore = new User();

export default userStore;