// 页面主题颜色
import { makeAutoObservable } from "mobx";
import type { SelectProps } from 'antd';

class Cate {
    options: SelectProps['options'];

    constructor () {
        this.options = []
        makeAutoObservable(this);
    };

    get theOptions () {
        return this.options;
    };

    setOptions (options: SelectProps['options']) {
        this.options = options;
    };
};

const cateStore = new Cate();

export default cateStore;