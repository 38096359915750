import React, { useEffect, useState} from 'react';
import { App, Row, Col, Form, Input, Select, DatePicker, Button, Image, Pagination } from 'antd';
import type { PaginationProps } from 'antd';

import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import CourseList from '../../components/courselist';
import CourseService from '../../services/courses.services';
import AuthService from '../../services/auth.services';
import OrderService from '../../services/orders.services';

import NiceModal from '@ebay/nice-modal-react';
import Login from '../account/login';

import { IOrder } from '../../types';

import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

type Props = {};

const Courses = (props: Props) => {
    //const { user } = useAuth();
    const { userStore, menuStore, themeStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});

    useEffect(() => {
        menuStore.setLeftCurrent("/courses/list");
    }, []);

    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({
        page_number: 1, 
        page_size: 20,
        count: 0,
        data: [],
    });
    const [refresh, setRefresh] = useState(0);

    const onFinish = (values:any) => {
        setCurrentPage(1);
        setRefresh(refresh+1);
    };

    const onClear = () => {
        setSearchParams({});
        form.resetFields();
        setCurrentPage(1);
        setRefresh(refresh+1);
    };

    useEffect(() => {
        let _zone = new Date();
        let zone = _zone.getTimezoneOffset()/60;
        if(AuthService.isAuthed()) {
            zone = -1 * userStore.zone;
        }

        let _name = searchParams.get('keyword');
        if(_name) {
            form.setFieldValue('name', _name);
        }
        let _cate_id = searchParams.get('cateid');
        let search = {
            zone: zone,
            name: form.getFieldValue("name"),
            cate_id: _cate_id,
            ctype: form.getFieldValue("ctype"),
            class_type: form.getFieldValue("class_type"),
            price_range: form.getFieldValue("price_range"),
            start_date_from: form.getFieldValue("start_date_range") ? form.getFieldValue("start_date_range")[0].format("YYYY-MM-DD"): "",
            start_date_to: form.getFieldValue("start_date_range") ? form.getFieldValue("start_date_range")[1].format("YYYY-MM-DD"): "",
            page_number: currentPage,
            page_size: 20,
        };

        CourseService.searchCourses(search)
        .then(response => {
            if(response.data.status === 200 && response.data.code === 0) {
                setData({
                    ...data,
                    data: response.data.data.data,
                    count: response.data.data.count,
                    page_number: response.data.data.page_number,
                    page_size: response.data.data.page_size,
                });
                return
            } else {
                setData({
                    ...data,
                    data:[],
                    count: 0,
                    page_number: 1,
                    page_size: 20,
                })
            }
        })
        .catch(error => {
            message.error(t('courses_error_fetch_course'));
            setData({
                ...data,
                data:[],
                count: 0,
                page_number: 1,
                page_size: 20,
            })
        })
    }, [currentPage, refresh]);

    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        // console.log('Page: ', pageNumber);
        setCurrentPage(pageNumber);
    };

    // enrollment
    const onEnroll = (e: React.MouseEvent<HTMLElement>, courseId: string | undefined, price: number | undefined) => {
        // console.log(e, courseId)
        if(courseId && price) {
            if(!AuthService.isAuthed()) {
                //NiceModal.show(Login, {forwardPath: "/courses/ordercourse/" + courseId});
                NiceModal.show(Login, {forwardPath: "/"});
            } else {
                // place order
                // placeOrder(courseId, price);
                checkOut(courseId, price);
            }
        }
    };

    const checkOut = (courseId: string, price: number) => {

    };

    const placeOrder = (courseId: string, price: number) => {
        let _order: IOrder = {
            course_id: courseId,
            price: price,
            buyer_id: userStore.userId.toString()
        }
        //console.log(_order)
        OrderService.createOrder(_order)
        .then(response=> {
            //console.log(response)
            if(response.data.status === 200 && response.data.code ===0) {
                message.error(t("enrollment_enroll_successful"));
                navigate("/console");
            }
            if(response.data.status === 200 && response.data.code ===2) {
                message.error(t("enrollment_enroll_already_enrolled"));
            }
            return;
        })
        .catch(error => {
            //console.log(error);
            message.error(t("enrollment_enroll_failed"))
        });
    };

    const onDetailItem = (e: React.MouseEvent<HTMLElement>, courseId: string | undefined ) => {
        // console.log(e, courseId)
        if(courseId) {
            window.open("/courses/coursedetail/" + courseId);
        }
    };

    //if (!user) {
    //    return <Navigate to="/login" />;
    //}
    
    //return (
    //    <div>Lessons { userStore.user_name} </div>
    //)

    return (
        <>
        <Row>
            <Form
            layout="inline"
            form={form}
            onFinish={onFinish}
            >
            {/*
                <Form.Item
                label={t("course_course_name")}
                name="name">
                    <Input
                    placeholder={t("course_placeholder_course_name")}
                    style={{
                        width: 100,
                    }} 
                    />
                </Form.Item>
                <Form.Item
                label={t("course_ctype_name")}
                name="ctype">
                    <Select
                    placeholder={t("course_placeholder_ctype_name")}
                    options={[
                        {
                            value: 0,
                            label: '不限',
                        },
                        {
                            value: 1,
                            label: '线上互助教学',
                        },
                        {
                            value: 2,
                            label: '第三方线上教学',
                        },
                        {
                            value: 3,
                            label: '视频录制课程',
                        },
                        {
                            value: 4,
                            label: 'AI交互课程',
                        },
                    ]}
                    />
                </Form.Item>
                <Form.Item
                label={t("course_class_type")}
                name="class_type">
                    <Select
                    placeholder={t("course_placeholder_class_type")}
                    options={[
                        {
                            value: 0,
                            label: '不限',
                        },
                        {
                            value: 1,
                            label: '1对1',
                        },
                        {
                            value: 2,
                            label: '1对多',
                        },
                        {
                            value: 3,
                            label: '其它',
                        },
                    ]}
                    />
                </Form.Item>
                */}
                <Form.Item 
                label={t("course_start_date")}
                name="start_date_range"
                >
                    <RangePicker
                    format="YYYY-MM-DD"
                    />
                </Form.Item>
                <Form.Item
                label={t("course_price_range")}
                name="price_range">
                    <Select
                    placeholder={t("course_placeholder_price_range")}
                    options={[
                        {
                            value: 0,
                            label: t("choice_no_limit"),
                        },
                        {
                            value: 1,
                            label: '<$100',
                        },
                        {
                            value: 2,
                            label: '$100 ~ $500',
                        },
                        {
                            value: 3,
                            label: '>=$500',
                        },
                    ]}
                    />
                </Form.Item>
                <Form.Item>
                    <Button size="small" type="primary" htmlType="submit">{t("button_search")}</Button>
                    <Button 
                    size="small"
                    style={{
                        margin: '0 8px',
                    }}
                    onClick={() => {
                        onClear();
                    }}
                    >
                        {t("button_reset")}
                    </Button>
                </Form.Item>
            </Form>
        </Row>
        {/*
        <Row>
            <Col span={24}>
                {t("分类浏览")}
            </Col>
        </Row>
        */}
        <Row style={{marginTop: "15px", marginBottom: "10px", fontWeight: 600}}>
            {t("search_result")}
        </Row>
        {
            (data && data.count === 0) ? t("no_data") : ( <>
                <Row>
                    {
                            data.count >= 20 ? 
                            <>
                            <Pagination defaultCurrent={currentPage} total={data.count} onChange={onChange} pageSize={20} />
                            </>: <></> 
                    }
                </Row>
                { /*
                <Row>
                        {
                            data ? data.data.map((m, idx) => (
                                        <Col span={8} key={m['key']} style={{marginTop: '10px', marginLeft: '10px'}}>
                                            <Image src={m['brief_picture_url']} style={{height: '160px', textAlign: "center"}} preview={false} 
                                            onClick={(e) => onDetailItem(e, m['key'])}
                                            />
                                            <Row>
                                                <Col span={18} style={{fontWeight: "550", marginTop:"5px", marginBottom: "5px"}}>{m['name']}</Col>
                                                <Col span={6}>{m['teacher_name']}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12} style={{height: "20px", marginTop:"5px", marginBottom: "5px"}}>{m['brief']}</Col>
                                                <Col span={12}>{t("开课时间")}: {m['start_date_time']}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12} style={{color: themeStore.themeColor, fontWeight: "550", }}>
                                                    ${m['price']}
                                                </Col>
                                                <Col span={12} style={{textAlign:"right"}}>
                                                    <Button size="small" type="primary" onClick={(e) => onEnroll(e, m['key'])}>{t("登记课程")}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                            )) : [] 
                        }
                </Row>
                */ }
                {/*
                    Array.from(Array(Math.round(data.data.length / 3)).keys()).map( (number, index) => {
                        return(
                            <Row>
                                {
                                    data.data.slice(index * 3, (index * 3) +3).map(m => {
                                        return(
                                            <Col span={8} key={m['key']} style={{marginTop: '10px', marginLeft: '10px'}}>
                                            <Image src={m['brief_picture_url']} style={{height: '160px', textAlign: "center"}} preview={false} 
                                            onClick={(e) => onDetailItem(e, m['key'])}
                                            />
                                            <Row>
                                                <Col span={18} style={{fontWeight: "550", marginTop:"5px", marginBottom: "5px"}}>{m['name']}</Col>
                                                <Col span={6}>{m['teacher_name']}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12} style={{height: "20px", marginTop:"5px", marginBottom: "5px"}}>{m['brief']}</Col>
                                                <Col span={12}>{t("开课时间")}: {m['start_date_time']}</Col>
                                            </Row>
                                            <Row>
                                                <Col span={12} style={{color: themeStore.themeColor, fontWeight: "550", }}>
                                                    ${m['price']}
                                                </Col>
                                                <Col span={12} style={{textAlign:"right"}}>
                                                    <Button size="small" type="primary" onClick={(e) => onEnroll(e, m['key'])}>{t("登记课程")}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        )
                                    })
                                }
                            </Row>
                        );
                    })
                */}
                <CourseList data={data.data} onEnroll={onEnroll} onDetailItem={onDetailItem}/>
            </>)
        }
        </>
    )
};

export default observer(Courses);