import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Image, App, Flex, Card, Button, Row, Col} from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import dayjs from 'dayjs';

import CourseService from '../../services/courses.services';
import AuthService from '../../services/auth.services';
import Lessonlist from './lessonlist';
import NiceModal from '@ebay/nice-modal-react';
import Login from '../account/login';
import { IOrder } from '../../types';
import OrderService from '../../services/orders.services';

type Props = {};

const CourseDetail = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();
    const navigate = useNavigate();

    const { course_id } = useParams();
    const [course, setCourse] = useState();

    const onButtonClick = () => {
        onEnroll(course_id, course&&course['discounted']);
    };

    const onEnroll = (courseId: string | undefined, price: number | undefined) => {
        // console.log(e, courseId, price);
        if(courseId && price) {
            if(!AuthService.isAuthed()) {
                //NiceModal.show(Login, {forwardPath: "/courses/ordercourse/" + courseId});
                NiceModal.show(Login, {forwardPath: "/"});
            } else {
                // place order
                // placeOrder(courseId, price);
                checkOut(courseId, price)
            }
        }
    };

    const checkOut = (courseId: string, price: number) => {

    };

    const placeOrder = (courseId: string, price: number) => {
        let _order: IOrder = {
            course_id: courseId,
            price: price,
            buyer_id: userStore.userId.toString()
        }
        //console.log(_order)
        OrderService.createOrder(_order)
        .then(response=> {
            //console.log(response)
            if(response.data.status === 200 && response.data.code ===0) {
                message.error(t("enrollment_enroll_successful"));
                navigate("/console");
            }
            if(response.data.status === 200 && response.data.code ===2) {
                message.error(t("enrollment_enroll_already_enrolled"));
            }
            return;
        })
        .catch(error => {
            //console.log(error);
            message.error(t("enrollment_enroll_failed"))
        });
    };

    const operations = <Button type="link" onClick={onButtonClick}>{t("com_course_list_enrollment")}</Button>;
 
    useEffect(() => {
        menuStore.setLeftCurrent("/courses/list");
    }, []);

    const convertDateTime = (value:any) => {
        let _zone = new Date();
        let zone = _zone.getTimezoneOffset()/60;
        if(AuthService.isAuthed()) {
            zone = -1 * userStore.zone;
        }
        let _utc_local = value.add(zone, 'hour');
        _utc_local = _utc_local.format("YYYY-MM-DD HH:mm");
        let _ret = <>{value.format("YYYY-MM-DD HH:mm")} <br/> {"Local:" + _utc_local }</>;
        return _ret;
    };

    useEffect(() => {
        let isUnmount = false;

        const fetchData = (course_id:string) => {
            if(course_id === undefined || course_id ===null || parseInt(course_id) <=0 ) {
                message.error(t("err_need_course_id"));
                return;
            }
            CourseService.fetchCourseById(BigInt(course_id))
            .then(response => {
                // console.log(response)
                if(response.data.status === 200) {
                    setCourse(response.data.data);
                }
            })
            .catch(error => {
                message.error(t("err_failed"));
            })
        }

        if(course_id) {
            fetchData(course_id);
        }

        return () => {
            isUnmount = true;
        }
    }, []);

    const tabList = [
        {
            key: 'lessonlist',
            tab: t("tab_lessons"),
        },
        {
          key: 'description',
          tab: t("tab_description"),
        },
        /*{
          key: 'comments',
          tab: t("tab_comments"),
        },*/
    ];

    const contentList: Record<string, React.ReactNode> = {
        lessonlist: <Lessonlist course_id={course && course['id']? course['id'] : ""} />,
        description: (course && course['description'])? <div dangerouslySetInnerHTML={{__html: course && course['description']}} /> : <>{t("no_data")}</>,
        comments: <p>{t("no_data")}</p>,
    };

    const [activeTabKey, setActiveTabKey] = useState<string>('description');

    const onTab1Change = (key: string) => {
        setActiveTabKey(key);
    };

    return (
        <div style={{marginTop:"20px", textAlign:"center"}}
        >
            <Flex vertical={false}>
                <div style={{height: "190px"}}>
                    <Image src={course && course['brief_picture_url']} preview={false} style={{height: "160px", width: "286px", marginTop: "30px"}}/>
                </div> 
                <div style={{marginLeft: "20px"}}>
                    <p>
                        <b>{course && course['name']}</b>
                    </p>
                    <table style={{width: "600px"}}>
                        <tbody>
                        <tr>
                            <td style={{textAlign: "right", width: "90px"}}>{t("course_cates")}：</td><td style={{textAlign: "left"}}>{course && course['cate_name']}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", width: "90px"}}>{t("course_discounted")}：</td><td style={{textAlign: "left"}}>${course && course['discounted']}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", width: "90px"}}>{t("com_course_list_teacher")}：</td><td style={{textAlign: "left"}}>{course && course['teacher_name']}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", width: "90px"}}>{t("course_students")}：</td><td style={{textAlign: "left"}}>{course && course['student_quto']}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", width: "90px"}}>{t("com_course_list_start_date")}：</td><td style={{textAlign: "left", width: "400px"}}>
                                <Row>
                                    <Col span={12}>{course && course['start_date_time'] ? convertDateTime(dayjs(course['start_date_time'])) : <></>}</Col>
                                    <Col span={12} style={{textAlign: "right"}}>{/* operations */}</Col>
                                </Row>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Flex>
            <Card
            style={{ width: '100%', textAlign: 'left'}}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTab1Change}
            >
                {contentList[activeTabKey]}
            </Card>
        </div>
    )
};

export default observer(CourseDetail);