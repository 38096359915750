import api from "./api";

import { IVerifyCode } from "../types";

const searchUserByKeyword = (keyword:string) => {
    let payload = {"keyword": keyword};
    return api.get("/api/common/users", {params: payload});
};

const searchTeacherByKeyword = (keyword:string) => {
    let payload = {"keyword": keyword};
    return api.get("/api/common/teachers", {params: payload});
};

const fetchVerifyCode = (verify_code: IVerifyCode) => {
    return api.post("/api/common/send_sms", verify_code);
};

const verifyCode = (verify_code: IVerifyCode) => {
    return api.post("/api/common/verify_sms", verify_code);
};

const CommonService = {
    searchUserByKeyword,
    searchTeacherByKeyword,
    fetchVerifyCode,
    verifyCode,
};

export default CommonService;