import React, {useEffect, useState, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Button, Descriptions,  App } from 'antd';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import { PayPalButtons } from '@paypal/react-paypal-js';
import { OnApproveData, OnApproveActions, CreateOrderData, 
    CreateOrderActions, OnCancelledActions } from "@paypal/paypal-js/types/components/buttons";
import { IOrder } from '../../types';

import OrderService from '../../services/orders.services';

type Props = {
};

const CheckOut = (props: Props) => {
    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();
    const navigate = useNavigate();

    useEffect(() => {
        menuStore.setLeftCurrent("");
    }, []);

    const { order_id } = useParams();
    const [order, setOrder] = useState();
    const [error, setError] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if(order_id === undefined || order_id ===null || parseInt(order_id) <=0 ) {
            message.error(t("err_need_order_id"));
            return;
        }
        OrderService.fetchOrderById(BigInt(order_id))
        .then(response => {
            // console.log(response)
            if(response.data.status === 200) {
                setOrder(response.data.data);
            }
        })
        .catch(error => {
            message.error(t("err_failed"));
        })
    };

    // paypal handling
    const onApproveOrder = (data: OnApproveData, actions: OnApproveActions) => {
        /**
         * data: {
         *   orderID: string;
         *   payerID: string;
         *   paymentID: string | null;
         *   billingToken: string | null;
         *   facilitatorAccesstoken: string;
         * }
         */
        if(actions.order) {
            return actions.order.capture().then((details) => {
                console.log(details)
                alert("Data details: " + JSON.stringify(data, null, 2));
                let _order : IOrder = {
                    id: order_id,
                    payment_method: 1,
                    paypal_url: "https://api.sandbox.paypal.com/v2/checkout/orders/" + data.orderID,
                    paypal_order_id: data.orderID,
                    paypal_result: JSON.stringify({details: details, data: data})
                }
                OrderService.updateOrderPayment(_order)
                .then( response => {
                    if(response.status === 200 && response.data.code === 0) {
                        alert(t("success_payit"))
                        navigate("/console")
                    } else {
                        alert(t("error2_payit"))
                    }
                })
                .catch( () => {
                    alert(t("error_payit"))
                })
            })
            .catch(error => {
                console.log(error)
            });
        } else {
            return new Promise((resolve:(value: void) => void, reject) => {
                alert(t("error_payit"))
                resolve();
            });
        }
    };

    const onCreateOrder = (data: CreateOrderData, actions: CreateOrderActions) => {
        return actions.order.create({
            intent: "CAPTURE",
            payer: {
                phone: {
                    phone_type: "MOBILE",
                    phone_number: {
                        country_code: "86",
                        national_number: userStore.account.toString(),
                    }
                }
            },
            purchase_units: [
                {
                    invoice_id: order && order['order_no'],
                    custom_id: order && order['buyer_id'],
                    amount: {
                        currency_code: "USD",
                        value: order ? order['price'] : "0.00",
                    },
                },
            ],
        });
    };

    const onCancel = (data: Record<string, unknown>, actions: OnCancelledActions) => {
        // message.error(t("cancel_payit"))
        alert(t("cancel_payit"));
    };

    const onError = (data: Record<string, unknown>) => {
        // message.error(t("cancel_payit"))
        //alert(data.toString())
        alert(t("cancel_payit"));
        let _error = JSON.parse(data.toString().replace("Error: ", ""))

        if(_error.status === 422 ) {
            alert(t("error_payit_422") + _error['body']['details'][0]['issue'])
            setError(_error['body']['details'][0]['issue'])
            //window.location.reload();
            JSON.parse(data.toString())
        }
    };
 
    return (
        <div style={{marginBottom:"20px", textAlign:"center"}}
        >
            <h3>{t('checkout_title')}</h3>
            <div style={{textAlign:"left"}}>
                <Descriptions title={t("checkout_info_title")} column={1} >
                    <Descriptions.Item label={t("checkout_course_name")}>{order && order['course_name']}</Descriptions.Item>
                    <Descriptions.Item label={t("checkout_cousrse_start_date_time")}>{order && order['course_start_date_time']}</Descriptions.Item>
                    <Descriptions.Item label={t("checkout_price")}>{order && order['price']}</Descriptions.Item>
                    <Descriptions.Item label={t("checkout_order_time")}>{order && order['order_time'] }</Descriptions.Item>
                    <Descriptions.Item label={t("checkout_status")}>
                        {
                            order ?  (order['order_status'] === 1 ? t("order_status_1") : 
                            (order['order_status'] === 2 ? t("order_status_2") : 
                            (order['order_status'] === 3 ? t("order_status_3") : 
                            (order['order_status'] === 4 ? t("order_status_4") : ""))) ) : "" 
                        }
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <div style={{width: "200px"}}>
            {error} <br />&nbsp;<br />
            {
                order && order['order_status'] === 1 ? 
                <PayPalButtons 
                style={{ layout: "vertical" }}
                createOrder={(data, actions) => onCreateOrder(data, actions)}
                onApprove={(data, actions) => onApproveOrder(data, actions)}
                onCancel={(data, actions) => onCancel(data, actions)}
                onError={(data) => onError(data) }
                /> : <></>
            }
            </div>
        </div>
    )
};

export default observer(CheckOut);