import api from "./api";
import {IMeResponse, IResponse, IAuthResponse, ILogin, IRegister, IPassword} from "../types/index";

const login = async (login: ILogin) : Promise<IAuthResponse> => {
    const response = await api.post("/auth", login);
    if (response.data.access_token) {
        let { access_token, refresh_token } = response.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
    }
    return response.data;
};

const me = async () : Promise<IMeResponse | null> => {
    const response = await api.get("/auth/me");
    if (response.data && response.data['me']) {
        let user = response.data['me'];
        // call localstorage at authprovider
        // localStorage.setItem('me', JSON.stringify(me));
        return user;
    } 
    return null;
};

const get_verify_code = (verify_code_id:string) => {
    return api.get("/api/verify_code/" + verify_code_id);
};

const register = async (user: IRegister) : Promise<IResponse> => {
    return api.post("/api/auth/register", user);
};

const isAuthed = () => {
    return localStorage.getItem("user")? true : false;
};

const logout = async () => {
    try {
        const res = await api.post("/api/auth/logout");
        localStorage.removeItem("user");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");
        return res;
    } catch {
        localStorage.removeItem("user");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");
    }
};

const change_password = (newPassword: IPassword) => {
    return api.post("/api/auth/password", newPassword);
};

const fetchProfile = (id: string) => {
    return api.get("/api/profile/" + id );
};

const AuthService = {
    login,
    me,
    get_verify_code,
    register,
    isAuthed,
    logout,
    change_password,
    fetchProfile,
};

export default AuthService;