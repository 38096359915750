import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import type { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { Calendar, Col, Row, Select, Typography, Descriptions, App } from 'antd';
import type { CalendarProps } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';

import { ILesson } from "../../types";
import CanlendarService from "../../services/calendar.services";

dayjs.extend(dayLocaleData);

type Props = {};

const TeacherCalendar = (props: Props) => {

    const { userStore, themeStore, menuStore } = useStore();
    let {t, i18n} = useTranslation();
    const {message} = App.useApp();

    const [data, setData] = useState<Map<string, ILesson[]>>();
    const [year, setYear] = useState(dayjs().year());
    const [month, setMonth] = useState(dayjs().month()+1);
    const [lessons, setLessons] = useState<ILesson[]>([]);
    const [dayString, setDayString] = useState(dayjs().format("YYYY-MM"));

    const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
        //console.log(value.format('YYYY-MM'), mode);
        //setYearMonth(value.format('YYYY-MM'));
        //console.log(value.month())
        setYear(value.year());
        setMonth(value.month()+1);
    };

    const onSelect = (newValue: Dayjs) => {
        //console.log(newValue.format('YYYY-MM-DD'));
        let _date_key = newValue.format("YYYY-MM-DD");
        setDayString(_date_key);
        //console.log(_date_key)
        let _lessons = data?.get(_date_key);
        //console.log(_lessons)
        if(_lessons){
            setLessons(_lessons);
        } else {
            setLessons([]);
        } 
    };

    const convertData = (data : any) => {
        // console.log(data);
        let _days = dayjs(new Date(year, month-1, 1)).daysInMonth();
        let _convertedData = new Map<string, ILesson[]>();
        for(var _i=0; _i<_days; _i++) {
            let _day = dayjs(new Date(year, month-1, _i+1)).format("YYYY-MM-DD");
            let _item :ILesson[] = [];
            _convertedData.set(_day, _item);
        }
        // console.log(_convertedData);
        for(var i=0; i< data.length; i++) {
            let _item = data[i];
            if(_convertedData.has(dayjs(_item['class_date_time']).add(-1 * userStore.zone, 'hour').format("YYYY-MM-DD"))) {
                _convertedData.get(dayjs(_item['class_date_time']).add(-1 * userStore.zone, 'hour').format("YYYY-MM-DD"))?.push(_item);
            }
        }
        // console.log(_convertedData)
        setData(_convertedData);
    };

    const convertDateTime = (value:any) => {
        let _local = value.add(-1 * userStore.zone, 'hour');
        _local = _local.format("YYYY-MM-DD HH:mm");
        let _ret = <>{value.format("YYYY-MM-DD HH:mm")} <br/> {"LO: " + _local }</>;
        return _ret;
    };

    useEffect(() => {
        let _search = {
            year: year,
            month: month,
            user_id: userStore.userId.toString(),
            zone: -1 * userStore.zone,
        };
        // console.log(month)
        CanlendarService.searchTeachCalendar(_search)
        .then(response => {
            if(response.data.status === 200 && response.data.code === 0) {
                convertData(response.data.data);
                //setData(response.data.data);
            } else {
                // setData([]);
                convertData([]);
            }
        })
        .catch(error => {
            message.error(t("canlendar_fetch_lesson_error"));
            // setData([]);
        })
    }, [year, month]);

    return(
        <>
        <div style={{marginBottom:"10px", textAlign:"center"}}
        >
            {/* 
            <h3>{t('console_tab_student_calendar')}</h3>
            */}
        </div>
        <Row>
            <Col span={12}>
                <Calendar
                fullscreen={false}
                headerRender={({value, type, onChange, onTypeChange}) => {
                    const start =0;
                    const end = 12;
                    const monthOptions = [];

                    let current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for(let i=0;i<12;i++) {
                        current = current.month(i);
                        months.push(localeData.monthsShort(current));
                    }
                    for(let i=start; i< end; i++) {
                        monthOptions.push(
                            <Select.Option key={i} value={i} className="month-item">
                                {months[i]}
                            </Select.Option>
                        )
                    }

                    const year = value.year();
                    const month = value.month();
                    const options = [];
                    for(let i=year-3; i< year + 5; i+=1) {
                        options.push(
                            <Select.Option key={i} value={i} className="year-item">
                                {i}
                            </Select.Option>,
                        );
                    }
                    return (
                        <div style={{ padding: 2}}>
                            <Typography.Title level={5}>{t('console_tab_teacher_calendar')}</Typography.Title>
                            <Row gutter={8}>
                                <Col>
                                <Select
                                size="small"
                                className="my-year-select"
                                value={year}
                                onChange={(newYear) => {
                                    const now = value.clone().year(newYear);
                                    onChange(now);
                                }}>
                                    {options}
                                </Select>
                                </Col>
                                <Col>
                                <Select
                                size="small"
                                value={month}
                                onChange={(newMonth) => {
                                    const now = value.clone().month(newMonth);
                                    onChange(now);
                                }}
                                >
                                    {monthOptions}
                                </Select>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
                cellRender={(date, info) => {
                    return (
                        <div>
                            {
                                data && data.has(date.format("YYYY-MM-DD")) && data.get(date.format("YYYY-MM-DD"))?.length ? 
                                <ExclamationOutlined style={{color: themeStore.themeColor}} /> : <>&nbsp;</>
                            }
                        </div>
                    );
                }}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
                />
            </Col>
            <Col span={12} style={{ paddingLeft: "20px", textAlign: "center" }}>
                <div style={{marginTop: "10px", fontSize:"18px", marginBottom: "10px"}}>{dayString}</div>
                {
                    lessons && lessons.length ? 
                    <>
                    {
                        lessons.map((item, index) => (
                            <Descriptions title={item.name} column={1} key={index}>
                                <Descriptions.Item label={t("lesson_course_name")}>
                                    {item.course_name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("lesson_class_time")}>
                                    {convertDateTime(dayjs(item.class_date_time))}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("lesson_class_room")}>
                                    {item.classroom_name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("lesson_student_name")}>
                                    {
                                        item.students && item.students.map((el, idx) => (
                                            <p key={idx}>
                                                {el['name']}
                                            </p>
                                        ))
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        )) 
                    }
                    </> :
                    <>{t("no_data")}</>
                }
            </Col>
        </Row>
        </>
    )

};

export default observer(TeacherCalendar);